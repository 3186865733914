.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 90vh;
}

.background-container {
  top: -5vh;
  width: 70vw;
  height: 80vh;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-container img{
  height: 600px;
  position: relative;
  right: -35%;
  bottom: -50%;
  z-index: 3;
}

.main-content h1 {
  margin: 0;
  font-size: 3.8rem;
  color: #1aa31a;
}

.overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.568);
  border: 1px solid rgba(0, 0, 0, 0.863);
  padding: 130px 150px;
  border-radius: 10px;
  text-align: center;
}

.overlay h2 {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 400;
  color: #afafaf;
}

.overlay a {
  position: relative;
  margin: 0;
  top: 75px;
  padding: 10px 45px;
  border: none;
  font-weight: 600;
  font-size: 18px;
  border-radius: 25px;
  background-color: white;
  text-decoration: none;
  color: black;
}

.overlay a:hover {
  background-image: linear-gradient(90deg, #55B037 0%, #ccc900 49%, #1aa32c 80%, #55B037 100%);
  color: white;
  animation: slidebg 5s linear infinite, pulse 1s infinite;
  transition: .3s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

/* Responsive styles */
@media (max-width: 1200px) {
  .overlay h1 {
    font-size: 3rem;
  }

  .overlay h2 {
    font-size: 2rem;
  }

  .overlay {
    padding: 100px 120px;
  }
  .background-container img{
    height: 380px;
    position: relative;
    right: -35%;
    bottom: -50%;
    z-index: 3;
  }
}

@media (max-width: 992px) {
  .background-container {
    width: 80vw;
    height: 70vh;
  }

  .overlay {
    padding: 80px 100px;
  }

  .overlay h1 {
    font-size: 2.5rem;
  }

  .overlay h2 {
    font-size: 1.8rem;
  }

  .overlay a {
    top: 50px;
  }

  .background-container img{
    height: 350px;
    position: relative;
    right: -30%;
    bottom: -50%;
    z-index: 3;
  }

}

@media (max-width: 768px) {
  .background-container {
    width: 90vw;
    height: 60vh;
  }

  .overlay {
    padding: 60px 80px;
  }

  .overlay h1 {
    font-size: 2rem;
  }

  .overlay h2 {
    font-size: 1.5rem;
  }

  .overlay a {
    top: 40px;
  }
  .background-container img{
    height: 300px;
    position: relative;
    right: -23%;
    bottom: -58%;
    z-index: 3;
  }
}

@media (max-width: 576px) {
  .background-container {
    width: 100vw;
    height: 50vh;
  }

  .overlay {
    padding: 40px 60px;
  }

  .overlay h1 {
    font-size: 1.8rem;
  }

  .overlay h2 {
    font-size: 1.2rem;
  }

  .overlay a {
    top: 30px;
    padding: 8px 30px;
    font-size: 16px;
  }
  .background-container img{
    height: 300px;
    position: relative;
    right: -8%;
    bottom: -90%;
    z-index: 3;
  }
}
