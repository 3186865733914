.avis-carousel {
  background-color: black; /* Fond noir */
  padding: 10px;
  padding-bottom: 40px;
}

.avis-carousel h2 {
  color: white;
}

.description {
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
  color: rgb(88, 88, 88);
}

.avis-item {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #d3d3d3;
  margin: 0 25px;
}

.avis-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avis-header h3 {
  margin-top: 0;
}

.avis-rating {
  color: #ffd700; /* couleur jaune pour les étoiles */
  font-size: 18px;
}

.star {
  margin-right: 5px;
}

.avis-text {
  margin-top: 10px;
  font-style: italic;
}

.avis-source {
  margin-top: 10px;
  font-size: 12px;
  color: #888;
}

.slick-slide {
  outline: none; 
}

.slick-dots li button:before {
  color: white; 
}

.slick-dots li.slick-active button:before {
  color: white; 
}