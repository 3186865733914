.about-section {
    padding: 80px 0;
    background-color: rgba(0, 0, 0, 0.8); /* Couleur de fond avec transparence */
    background-size: cover; /* Assure que l'image de fond couvre toute la section */
    background-position: center; /* Centre l'image de fond */
    background-repeat: no-repeat; /* Empêche la répétition de l'image */
    background-blend-mode: overlay; /* Mélange l'image avec la couleur de fond */
  }
  
  .container {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .section-title {
    text-align: center;
    font-size: 36px;
    color: #ffffff;
    margin-bottom: 40px;
  }
  
  .about-content {
    text-align: justify;
    line-height: 1.6;
    color: rgb(156, 156, 156);
  }

  .button-about {
    text-align: center;
  }
  
  .about-content a {
    position: relative;
    margin: 0;
    justify-content: center;
    align-items: center;
    padding: 10px 45px;
    border: none;
    font-weight: 600;
    font-size: 18px;
    border-radius: 25px;
    background-color: white;
    color: black;
  }

  .about-content a:hover {
    background-image: linear-gradient(90deg, #55B037 0%, #ccc900 49%, #1aa32c 80%, #55B037 100%);
    color: white;
    animation: slidebg 5s linear infinite;
   transition: .3s;
 }

  @keyframes slidebg {
    to {
      background-position:20vw;
    }
  }
  
  .vision-section, .skills-section {
    margin-top: 40px;
    color: rgb(156, 156, 156);
  }
  
  .vision-section h3, .skills-section h3 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .skills-section ul {
    list-style-type: none;
    padding-left: 20px;
  }
  
  .skills-section li {
    font-size: 18px;
    color: #c9c9c9;
    margin-bottom: 10px;
  }
  