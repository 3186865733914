.produits-section {

    padding: 80px 20px;
    background-color: rgba(0, 0, 0, 0.8); /* Couleur de fond avec transparence */
    background-size: cover; /* Assure que l'image de fond couvre toute la section */
    background-position: center; /* Centre l'image de fond */
    background-repeat: no-repeat; /* Empêche la répétition de l'image */
    background-blend-mode: overlay; /* Mélange l'image avec la couleur de fond */
  }
  
  .description {
    font-size: 18px; /* Augmenter la taille de la police */
    margin-bottom: 20px;
    font-weight: 300;
  }
  
  .produits-section h2 {
    margin-bottom: 30px;
    color: white;
    font-size: 28px;
    text-align: center;
  }
  
  .white {
    color: white;
  }
  
  .produit-card {
    display: flex;
    background-color: #ffffff; /* Couleur de fond de la carte produit */
    border-radius: 10px; /* Arrondir les coins */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ajouter une ombre */
    overflow: hidden; /* Masquer les débordements */
    text-align: left; /* Aligner le texte à gauche */
  }
  
  .produit-image {
    width: 40%; /* Prendre 40% de la largeur de la carte */
    object-fit: cover; /* Couvrir l'espace disponible */
    border-radius: 10px;
  }
  
  .produit-details {
    padding: 20px; /* Ajouter du padding autour du texte */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centrer verticalement */
    color: white;
  }
  
  .produit-details h3 {
    margin-top: 0;
    font-size: 24px;
    color: white;
  }
  
  .produit-description {
    font-size: 16px; /* Augmenter la taille de la police */
    margin: 20px 0;
    font-weight: 300; /* Rendre le texte plus fin */
    color: #bbb; /* Couleur de texte légèrement plus claire */
  }
  
  .produit-cta {
    display: inline-block;
    padding: 12px 25px; /* Agrandir le bouton */
    background-color: white;
    color: black;
    text-decoration: none;
    border-radius: 25px;
    font-weight: 600;
    transition: background-color 0.3s, transform 0.3s; /* Ajouter une transition pour l'animation */
    align-self: flex-start; /* Aligner le bouton à gauche */
  }
  
  .produit-cta:hover {
    background-image: linear-gradient(90deg, #55B037 0%, #ccc900 49%, #1aa32c 80%, #55B037 100%);
    color: white;
    animation: slidebg 5s linear infinite;
   transition: .3s;
 }

  @keyframes slidebg {
    to {
      background-position:20vw;
    }
  }