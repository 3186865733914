.portfolio-page {
    font-family: Arial, sans-serif;
    text-align: left;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    background-blend-mode: overlay;
    background-size: cover; /* Assure que l'image couvre tout le fond */
    background-position: center; /* Centre l'image de fond */
    min-height: 100vh;
}

.title-page-portfolio {
    text-align: center;
}

.portfolio {
    max-width: 820px;
    margin: 0 auto;
    padding: 20px;
}

.portfolio h1 {
    color: white;
}

.portfolio p {
    color: rgb(126, 125, 125);
    text-align: center;
    font-size: 16px;
    margin-bottom: 40px;
}

.portfolio-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.portfolio-item {
    width: 400px;
    cursor: pointer;
    transition: transform 0.3s;
}

.portfolio-item img {
    width: 100%;
    border-radius: 5px;
}

.portfolio-item h3 {
    margin: 10px 0 0;
    font-size: 18px;
    color: #acacac;
}

.portfolio-item:hover {
    transform: scale(1.05);
}


.project-infor {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.project-infor h3 {
    margin: 0;
    font-size: 18px;
    color: #acacac;
}

.customer {
    margin: 0;
    font-size: 18px;
}

.customer-name {
    color: #808080;
    font-size: 16px;
}

.project-tags {
    margin-top: 10px;
}

.project-tag {
    display: inline-block;
    background-color: #333;
    color: white;
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 3px;
}

@media (max-width: 576px) {
    .portfolio-item {
        width: 350px;
        cursor: pointer;
        transition: transform 0.3s;
    }
  }