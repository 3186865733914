.container-FAQ {
    background-color: black;
}
.content-FAQ {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: black;
  }
  
  .content-FAQ h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    color: white;
  }
  