.anounce-bar {
    background-image: linear-gradient(90deg, #55B037 0%, #ccc900 49%, #1aa32c 80%, #55B037 100%);
    margin-top: 0;
    position: relative;
    top: 0;
    height: 6.5vh;
    text-align: center;
    justify-content: center;
    padding: 0.1px 0.5px;
    color: white;
    animation: slidebg 5s infinite;
    text-decoration: none;
    text-decoration-line: none;
    transition: .3s;
}

a {
    text-decoration: none;
}

@keyframes slidebg {
    to {
      background-position:200vw;
    }
  }
  
  @media (max-width: 676px) {
    .anounce-bar {
      font-size: 14px;
    }

  }
  @media (max-width: 476px) {
    .anounce-bar {
      font-size: 12px;
    }
  }