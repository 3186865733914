.partenaires-carousel {
    background-color: rgb(0, 0, 0); /* Fond noir */
    padding: 20px;
    color: white; /* Couleur du texte en blanc pour contraster avec le fond noir */
  }
  
  .description {
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .partenaires-carousel h2 {
    text-align: center;
  }
  
  .partenaire-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0;
  }

  .partenaire-item a {
    margin: 0!important;
    padding: 0!important;
  }
  
  .partenaire-logo {
    max-width: 100%;
    max-height: 80px; /* Ajustez selon vos besoins */
    border-radius: 5px;
  }
  
  .slick-slide {
    outline: none; /* Supprime la bordure de focus par défaut sur les éléments de carrousel */
  }
  
  /* Styles pour les dots */
  .slick-dots li button:before {
    color: white; /* Couleur des dots en blanc */
  }
  
  .slick-dots li.slick-active button:before {
    color: white; /* Couleur des dots actifs en blanc */
  }
  