.social-content {
  align-items: center;
  justify-content: center;
  height: 20vh;
  margin-top: -20px;
  background-color: black;
}

.social-content > h2 {
    color: white;
  margin-bottom: 20px;
}

.icon-container {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 5px 10px;
}
