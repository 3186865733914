.devis-portfolio-section {
    color: white; /* Texte en blanc */
    padding: 40px 20px; /* Ajouter du padding */
    text-align: center; /* Centrer le texte */
    background-color: rgba(0, 0, 0, 0.8); /* Couleur de fond avec transparence */
    background-size: cover; /* Assure que l'image de fond couvre toute la section */
    background-position: center; /* Centre l'image de fond */
    background-repeat: no-repeat; /* Empêche la répétition de l'image */
    background-blend-mode: overlay; /* Mélange l'image avec la couleur de fond */
  }
  
  .devis-section {
    margin-bottom: 40px; /* Espacement entre les sections */
  }
  
  .devis-section h2 {
    font-size: 24px; /* Taille de la police du titre */
    margin-bottom: 20px; /* Espacement en dessous du titre */
  }
  
  .devis-section p {
    font-size: 16px; /* Taille de la police du texte */
    margin-bottom: 20px; /* Espacement en dessous du texte */
  }
  
  .cta-button {
    display: inline-block;
    padding: 12px 25px; /* Agrandir le bouton */
    color: black;
    text-decoration: none;
    border-radius: 25px;
    font-weight: 600;
    background: white;
    margin: 10px; /* Ajouter un peu d'espace entre les boutons */
  }

  .cta-button:hover {
    background-image: linear-gradient(90deg, #55B037 0%, #ccc900 49%, #1aa32c 80%, #55B037 100%);
    color: white;
    animation: slidebg 5s linear infinite;
   transition: .3s;
 }

  @keyframes slidebg {
    to {
      background-position:20vw;
    }
  }

  
  .portfolio-devis-section {
    margin-top: 40px;
  }
  
  .portfolio-devis-section h2 {
    font-size: 24px; /* Taille de la police du titre */
    margin-bottom: 20px; /* Espacement en dessous du titre */
  }
  
  .portfolio-devis-item {
    padding: 10px; /* Ajouter du padding autour des images */

  }
  
  .portfolio-devis-image {
    width: 80%; /* Prendre toute la largeur du conteneur */
    height: auto; /* Garder les proportions de l'image */
    border-radius: 10px; /* Arrondir les coins */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ajouter une ombre */
  }
  
  .portfolio-devis-buttons {
    margin-top: 20px; /* Espacement en haut des boutons */
    display: flex;
    justify-content: center; /* Centrer les boutons */
    gap: 20px; /* Ajouter un espace entre les boutons */
  }
  