.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 70px;
  background-color: #000;
}

.navbar-logo img {
  height: 70px;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.navbar-links li {
  display: inline;
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 6px;
}

.navbar-links a:hover {
  background-color: rgb(235, 234, 234);
  color: black;
}

.navbar-links a.selected {
  background-color: rgb(235, 234, 234);
  color: black;
}

.navbar-hamburger {
  display: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar-hamburger {
    display: block;
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 90px; /* Ajustez cette valeur en fonction de la hauteur de votre navbar */
    left: 0;
    background-color: #000;
    padding: 40px 5px;
    gap: 10px;
    z-index: 1;
  }

  .navbar-links.open {
    display: flex;
  }

  .navbar-links li {
    display: block;
    text-align: center;
  }
}

.navbar-language-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.navbar-language-dropdown img {
  width: 20px;
  height: 20px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
}

.dropdown-menu div {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-menu div img {
  margin-right: 8px;
}

.dropdown-menu div:hover {
  background-color: #f0f0f0;
}
