.content-CGV {
    background-color: black;
}
.conditions-general-ventes {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #858585;
    background-color: black;
  }
  
  .conditions-general-ventes h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .conditions-general-ventes section {
    margin-bottom: 20px;
  }
  
  .conditions-general-ventes h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #b9b9b9;
  }
  
  .conditions-general-ventes p {
    margin: 0;
  }
  