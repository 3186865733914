/* .container-FAQitem {
     background-color: black;
     color: white;
} */
.faq-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .faq-question {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.2em;
    font-weight: bold;
    color: #d1d1d1;
  }
  
  .faq-toggle {
    margin-right: 10px;
    font-size: 1.5em;
    color: #55B037;
  }
  
  .faq-answer {
    padding: 10px 0;
    font-size: 1em;
    color: #929292;
  }
  