/* Styles pour le composant de paiement Stripe */

.payment-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
}

.payment-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.payment-form {
    margin-bottom: 20px;
}

.payment-form label {
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.payment-form input[type="text"],
.payment-form input[type="email"],
.payment-form input[type="tel"],
.payment-form input[type="number"],
.payment-form input[type="date"],
.payment-form input[type="password"],
.payment-form select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.payment-form input[type="text"]:focus,
.payment-form input[type="email"]:focus,
.payment-form input[type="tel"]:focus,
.payment-form input[type="number"]:focus,
.payment-form input[type="date"]:focus,
.payment-form input[type="password"]:focus,
.payment-form select:focus {
    outline: none;
    border-color: #1aa31a; /* Couleur de mise au point */
}

.payment-form .card-element {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.payment-form button {
    background-color: #1aa31a;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.payment-form button:hover {
    background-color: #148914;
}

.payment-result {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
}

.payment-result.success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
}

.payment-result.error {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
}

.payment-result p {
    font-size: 16px;
    margin: 0;
    padding: 8px;
}

@media (max-width: 768px) {
    .payment-container {
        width: 100%;
        padding: 10px;
    }
}
