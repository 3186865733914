.footer {
    background-color: black;
    border-top: 1px solid #444;
    color: #fff;
    padding: 40px 70px;
    font-family: Arial, sans-serif;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
  }
  
  .footer-logo-section {
    flex: 1;
  }
  
  .footer-logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .footer-links {
    display: flex;
    flex: 2;
    justify-content: space-around;
  }
  
  .footer-column h4 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-column li {
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  .footer-contact {
    flex: 1;
    text-align: right;
  }
  
  .footer-contact p {
    margin: 5px 0;
  }
  
  .footer-contact a {
    color: #55B037;
    text-decoration: none;
  }
  
  .footer-social  {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 5px 10px;

  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #444;
  }
  
  .footer-bottom p {
    margin: 0;
    font-size: 14px;
  }
  
  .footer-bottom-links {
    margin-top: 10px;
  }
  
  .footer-bottom-links a {
    color: #1aa32c;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .footer-column a {
    text-decoration: none;
    color: white;
  }