.project-content {
    font-family: Arial, sans-serif;
    text-align: left;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}
.project-detail {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: black;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.back-button {
    background-color: #9e2a2a;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 0px;
}

.project-detail-content {
    text-align: center;
}

.project-detail-content h2 {
    color: white;
}

.image-viewer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.image-viewer img {
    max-width: 100%;
    max-height: 500px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-button {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.nav-button.left {
    left: 10px;
}

.nav-button.right {.project-detail {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.back-button {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
}

.project-detail-content {
    text-align: center;
}



.image-viewer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.image-viewer img {
    max-width: 100%;
    max-height: 500px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-button {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.nav-button.left {
    left: 10px;
}

.nav-button.right {
    right: 10px;
}

.project-description {
    margin-bottom: 20px;
    text-align: center;
}

.project-description p {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    text-align: center;
}

.project-info {
    text-align: left;
    align-items: left;
    margin-top: 20px;
}

.project-info p {
    font-size: 16px;
    color: #333;
    margin: 5px 0;
}

    right: 10px;
}

.project-detail-content p {
    text-align: left;
    font-size: 16px;
    color: #666;
    margin-top: 10px;
}
