.products-page {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

.products {
    max-width: 820px;
    margin: 0 auto;
    padding: 20px;
}

.products h1 {
    color: white;
}

.products p {
    color: rgb(126, 125, 125);
    text-align: center;
    font-size: 16px;
    margin-bottom: 40px;
}

.products-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.products-item {
    width: 400px;
    cursor: pointer;
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.products-item img {
    width: 100%;
    border-radius: 5px;
}

.product-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.product-info h3 {
    margin: 0;
    font-size: 18px;
    color: #acacac;
}

.price {
    margin: 0;
    font-size: 18px;
}

.original-price {
    text-decoration: line-through;
    margin-right: 10px;
    color: #ff4c4c;
}

.discount-price {
    color: #3ea33e;
}

.tags {
    margin-top: 10px;
}

.tag {
    display: inline-block;
    background-color: #333;
    color: white;
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 3px;
}

.products-item:hover {
    transform: scale(1.05);
}

@media (max-width: 576px) {
    .products-item {
        width: 350px;
        cursor: pointer;
        transition: transform 0.3s;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
  }