.produit-content {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

.produit-detail {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: black;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    color: white; /* Couleur de texte pour tout le contenu */
    margin-top: 40px;
}

.back-button {
    background-color: #9e2a2a;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
}

.produit-description {
    margin-top: 20px;
}

.produit-description p {
    font-size: 16px;
    line-height: 1.5;
}

.produit-title-detail {}

.price-and-buy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.price-and-buy p {
    font-size: 16px;
    color: #666;
}

.price-and-buy .original-price {
    text-decoration: line-through;
    margin-right: 10px;
    color: #ff4c4c;
}

.price-and-buy .discount-price {
    color: #1aa31a;
}

.buy-button {
    background-color: #1aa31a;
    color: white;
    padding: 10px 77px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.buy-button:hover {
    background-image: linear-gradient(90deg, #55B037 0%, #ccc900 49%, #1aa32c 80%, #55B037 100%);
    color: white;
    animation: slidebg 5s linear infinite;
   transition: .3s;
}

@keyframes slidebg {
    to {
      background-position: 20vw;
    }
}

.image-viewer {
    position: relative;
    margin-top: 20px;
    text-align: center;
}

.image-viewer img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-button {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.nav-button.left {
    left: 10px;
}

.nav-button.right {
    right: 10px;
}

.produit-info {
    margin-top: 20px;
}

.produit-info p {
    font-size: 16px;
    color: #333;
    margin: 5px 0;
}

.produit-info a {
    color: #3ea33e;
    text-decoration: none;
}

.produit-info a:hover {
    text-decoration: underline;
}

.icon-paiement {
    display: flex;
    justify-content: right;
    text-align: right;
    gap: 10px;
    margin-top: -30px;
}
