.stats-content {
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: black;
  }
  
  .stats-content h2 {
      color: white;
      margin-left: 35px;
  }
  

.stats-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #000000;
  }
   
  .stat-item {
    text-align: center;
  }
  
  .stat-title {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 5px;
  }
  
  .stat-value {
    font-size: 24px;
    font-weight: bold;
    color: #55B037; /* Exemple de couleur pour la valeur */
  }
  