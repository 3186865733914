.patchnote-content {
    background-size: cover; /* Assure que l'image couvre tout le fond */
    background-position: center; /* Centre l'image de fond */
    padding: 80px 30px; /* Ajouter du padding */
    background-color: rgba(0, 0, 0, 0.8); /* Couleur de fond avec transparence */
    text-align: center; /* Centre le texte */
    color: white; /* Couleur du texte en blanc */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ajouter une ombre */
    background-blend-mode: overlay;
  }
  
  .patchnote-content h2 {
    font-size: 36px;
    margin-bottom: 40px;
  }
  
  .patchnote-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .patchnote-item {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .patchnote-item h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .patchnote-item p {
    font-size: 16px;
    margin: 5px 0;
  }
  
  .patchnote-item strong {
    color: #55B037;
  }
  
  .patchnote-date {
    margin-top: 10px;
    font-size: 14px;
    color: #ccc;
    text-align: right;
  }
  
  .patchnote-images {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 10px;
  }
  
  .patchnote-images img {
    width: 100px;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }