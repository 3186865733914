.contact-content {
  background-color: rgba(0, 0, 0, 0.8);
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  padding: 80px 0;
}

.contact-container {
  background-color: black;
  color: #fff;
  padding: 35px;
  border-radius: 8px;
  width: 80%;
  margin: 0 auto;
  max-width: 600px;
}

.contact-container h2 {
  color: #fff;
  font-size: 2em;
  margin-bottom: 10px;
}

.contact-container p {
  color: #b3b3b3;
  font-size: 1em;
  margin-bottom: 40px;
}

.button-container {
  text-align: center;
  margin-bottom: 20px;
}

.form-toggle-button {
  background-color: #444;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.form-toggle-button.active, .form-toggle-button:hover {
  background-color: #666;
}

.contact-form, .quote-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-container h2 {
  text-align: left;
  margin-bottom: 20px;
}

.contact-container p {
  text-align: left;
  margin-bottom: 40px;
}

label {
  margin-bottom: 5px;
  text-align: left;
  font-weight: 600;
  width: 100%;
  color: #fff;
  font-size: 1em;
}

.input-row {
  display: flex;
  justify-content: space-between;
  width: 98%;
}

.input-row .input-group {
  width: 47%;
}

input[type="text"], input[type="email"], input[type="file"], textarea {
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  width: 96.25%;
}

textarea {
  resize: none;
  padding: 10px;
  height: 150px;
}

input[type="text"]::placeholder, input[type="email"]::placeholder, textarea::placeholder {
  color: #bbb;
}

button[type="submit"] {
  background-color: white;
  color: black;
  border: none;
  padding: 10px 5px;
  width: 100%;
  cursor: pointer;
  border-radius: 25px;
  font-weight: 600;
  transition: background-color 0.3s;
  align-self: flex-start;
}

button[type="submit"]:hover {
  background-image: linear-gradient(90deg, #55B037 0%, #ccc900 49%, #1aa32c 80%, #55B037 100%);
  color: white;
  animation: slidebg 2.5s linear infinite;
 transition: .3s;
}

@keyframes slidebg {
  to {
    background-position:20vw;
  }
}

button[type="submit"]:active {
  background-color: #cc6600;
}
